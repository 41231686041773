import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {
  
    BASE_URL_FOR_ADMIN,
    GETALL_Dynamic,
    GETSINGLE_BTN,
    UPDATE_SINGLE_BTN,

} from "../../UrlConfig";
import { toast } from "react-toastify";


const initialState = {
  isLoading: false,
  getAllDynamicData: [],
  getSingleBTN:[],
  editBTNData: [],
 
};


export const getSingleBTN = createAsyncThunk(
  "admin/GETSINGLE_BTN",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL_FOR_ADMIN + GETSINGLE_BTN}``${payload.id}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response get version by id",response.data.data)
      return response?.data?.data;
    } catch (error) {
      console.log("error" , error)
      toast.error("Somthing went wrong");
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllDynamic = createAsyncThunk(
    "admin/GETALL_Dynamic",
    async ({ payload }, { rejectWithValue }) => {
      try {
        const response = await axios.get(
          `${BASE_URL_FOR_ADMIN + GETALL_Dynamic}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response get all version",response.data.data)
        return response?.data?.data;
      } catch (error) {
        console.log("error" , error)
        toast.error("Somthing went wrong");
        return rejectWithValue(error.response.data);
      }
    }
  );


export const updateSingleBTN = createAsyncThunk(
  "admin/UPDATE_SINGLE_BTN",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        BASE_URL_FOR_ADMIN + UPDATE_SINGLE_BTN +payload.id,
        payload,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": 'application/json',
          },
        }
      );
      toast.success("Version updated successfully");
      return response?.data?.data;
    } catch (error) {
      console.log("error in Version update" , error)
      toast.error("Somthing went wrong");
      return rejectWithValue(error.response.data);
    }
  }
);




const popcardsSlice = createSlice({
  name: "popcardsSlice",
  initialState,
  extraReducers: (builder) => {
    builder
    .addCase(getAllDynamic.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllDynamic.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllDynamicData = action.payload;
    })
    .addCase(getAllDynamic.rejected, (state) => {
      state.isLoading = false;
    })

      .addCase(getSingleBTN.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleBTN.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getSingleBTN = action.payload;
      })
      .addCase(getSingleBTN.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSingleBTN.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSingleBTN.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editBTNData = action.payload;
      })
      .addCase(updateSingleBTN.rejected, (state) => {
        state.isLoading = false;
      })

      
  },
});

export default popcardsSlice.reducer;
